$root-font-size: 16;
@use "sass:math";

@function fontSize($size) {
	//$remSize:  $size / $root-font-size;
	$remSize:  math.div($size,$root-font-size);
	@return #{$remSize}rem;
}

$fs: 48;
@while $fs > 4 {
	.fs-#{$fs} {
		//font-size: #{$fs / $root-font-size}rem;
		font-size: #{math.div($fs,$root-font-size)}rem;
	}
	$fs: $fs - 1;
}

$line-height: 38;
@while $line-height > 8 {
	.line-height-#{$line-height} {
		line-height: $line-height * 1px;
	}
	$line-height: $line-height - 1;
}

$fw: 800;
@while $fw > 200 {
	.fw-#{$fw} {
		font-weight: $fw;
	}
	$fw: $fw - 100;
}

$height: 700;
@while $height > 400 {
	.h-#{$height} {
		height: $height;
	}
	$height: $height - 50;
}

$border-radius: 20;
@while $border-radius > 10 {
	.br-#{$border-radius} {
		border-radius: $border-radius;
	}
	$border-radius: $border-radius - 1;
}
