@import "assets/scss/abstracts/variables.scss";

.extension-layout-wrapper {
    width: calc(100% / 5 - 24px);
    background: $white;
    box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
    border-radius: 8px;
    padding: 16px;

    @media(max-width: 600px) {
        width: 132px;
        margin-inline-end: 12px;
        flex: 132px 0 auto;
    }

    .extension-service-card {
        .extensionIcon-wrapper {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            @media(max-width: 600px) {
                width: 37px;
                height: 37px;
            }

            .extensionIcon {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .extension-content {
            font-weight: 800;
            font-size: 1.5rem;
            line-height: 120%;
            margin-bottom: 0;

            @media(max-width: 600px) {
                font-size: 1rem;
            }
        }
    }
}