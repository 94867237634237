@import "assets/scss/abstracts/variables.scss";

.form_wrapper {
	.p-inputtext {
		font-size: 0.9rem;
		border: none;
		border-bottom: 1px solid rgba(26, 28, 31, 0.1);
		// margin-inline-start: 8px;
	}

	.id-input {
		border-bottom: 1px solid rgba(26, 28, 31, 0.1) !important;
	}

	label {
		margin-bottom: 0;
		font-style: normal;
		font-weight: 800;
		font-size: 0.9rem;
		line-height: 140%;
		display: flex;
		align-items: center;
		color: $eerie-black;
		// padding: 0 0.5rem;
	}

	::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: $newLight-tw;
		opacity: 0.5;
	}

	.p-input-icon-left {
		i {
			top: 45%;
		}
	}

	.p-input-icon-left {
		&>.p-inputtext {
			//	padding-inline-start: 3rem;
		}

		i {
			top: 45%;
		}
	}

	.pi {
		font-family: "primeicons";
		color: $cadmium-orange;
	}

	.p-dropdown {
		border: none;
		border-bottom: 1px solid rgba(26, 28, 31, 0.1);
	}

	.p-calendar-w-btn-right .p-datepicker-trigger {
		border: none;
		border-bottom: 1px solid rgba(26, 28, 31, 0.1);
		background: transparent;
	}

	.p-button-outlined {
		color: $primary-tw !important;
		border-radius: 8px;

		.p-button-label {
			font-weight: 400 !important;
		}
	}

	.line {
		color: $lightWhite-tw;
		margin-left: 8px;
		margin-right: 8px;
	}

	.or-sec {
		color: $newLight-tw;
		background: $anti-flash-white;
		border-radius: 50%;
		padding: 6px 8px;
	}

	.or-sec-rtl {
		color: $newLight-tw;
		background: $anti-flash-white;
		border-radius: 50%;
		padding: 6px 12px;
	}
}

.mobile-number {
	padding-inline-start: 3rem;
}

.padd-national {
	padding-right: 0px !important;
}

.Select__single-value {
	// font-family: "Bliss 2" !important;
	font-weight: 700;
	color: #495057;
	font-size: 1rem;
	font-family: "tawuniya" !important;
}

//

.rmdp-container {
	div {
		z-index: 99999 !important;
	}
}

.react-datepicker-popper {
	z-index: 9999 !important;
}

.rmdp-calendar {
	height: max-content;
	padding: 8px;
}

.rmdp-header {
	font-size: 14px;
	height: 38px;
	line-height: 37px;
	margin-top: 5px;
}

.rmdp-arrow-container {
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 20px;
	justify-content: center;
	margin: 0 5px;
	width: 20px;
}

.rmdp-left i {
	margin-left: 3px;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.rmdp-arrow {
	border: solid #0074d9;
	border-width: 0 2px 2px 0;
	display: inline-block;
	height: 3px;
	margin-top: 5px;
	padding: 2px;
	width: 3px;
}

.rmdp-header-values {
	color: #000;
	margin: auto;
}

.rmdp-arrow-container {
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 20px;
	justify-content: center;
	margin: 0 5px;
	width: 20px;
}

.rmdp-day-picker {
	padding: 5px;
}

.rmdp-week-day,
.rmdp-day {
	height: 60px !important;
	width: 60px !important;

	@media (max-width: 767px) {
		height: 40px !important;
		width: 40px !important;
	}
}

.rmdp-week-day {
	height: 60px;
	width: 60px;
}

.rmdp-week,
.rmdp-ym {
	display: flex;
	justify-content: space-between;
}

.rmdp-day span {
	border-radius: 50%;
	bottom: 3px;
	font-size: 14px;
	left: 3px;
	position: absolute;
	right: 3px;
	top: 3px;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
	background-color: #0074d9;
	box-shadow: 0 0 3px #8798ad;
	color: #fff;
}

.bottom-date {
	border-bottom: 1px solid rgba(26, 28, 31, 0.1) !important;
}