@import "../../../assets/scss/abstracts/abstracts";
@import "assets/scss/abstracts/colors.scss";

.normal-search {
  position: relative;

  #userIdplaceHolderAlign {
    &::placeholder {
      text-align: right;
      font-weight: 300 !important;
    }
  }

  .loginInputFieldOne {
    border: none;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
  }

  .searchBox {
    width: 100%;
    font-size: fontSize(14px);
    line-height: 16px;
    color: $eerie-black;
    padding: 10px 40px 10px 16px;
    height: 47px;
    border: none;

    &:focus {
      outline: none;
      background-color: $white;
    }

    &::placeholder {
      color: $silver;
      font-weight: 400;
      font-size: 1rem;
      text-align: left !important;
    }
  }

  .searchBox-rtl {
    &::placeholder {
      text-align: right !important;
    }
  }

  .padLeftIcon {
    padding-left: 60px !important;
  }

  .leftIconBox {
    .leftIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-57%);

      .Inputicon {
        transform: translateX(-2px);
      }
    }
  }

  .rightIconBox {
    .rightIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-57%);
      width: fit-content;

      .Inputicon {
        transform: translateX(22px);
      }
    }
  }

  .search-icon-rtl {
    cursor: pointer;

    .icon-search:before {
      color: $text;
    }

    i {
      position: absolute;
      // right: 8px !important;
      top: 13px;
    }
  }

  .search-icon {
    cursor: pointer;

    .icon-search:before {
      color: $bright-purple;
    }

    i {
      position: absolute;
      right: 10px;
      top: 13px;


    }
  }
}

.language-alignment-right {

  // .normal-search {
  //   .leftIconBox {
  //     .leftIcon {
  //       .Inputicon {
  //         transform: translateX(-22px);
  //       }
  //     }
  //   }
  // }
  .searchBox-rtl {
    &::placeholder {
      padding-right: 10px;
    }
  }

  .normal-search .rightIconBox .rightIcon .Inputicon {
    transform: translateX(-7px);
  }

  #root>div.vh-100.\31>div:nth-child(1)>div.col-12.paddingContainer.landingContainer>div>div>div.products-enquiry-form-root-common.mt-5>div.forms-list-root>div>div>div>div img {
    transform: translateX(0);
    margin-left: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .normal-search {
    .searchBox-rtl {
      padding: 10px 53px 10px 51px !important;
      border: none;

      &:focus {
        background-color: $white;
      }

      &::placeholder {
        color: $silver;
        font-weight: 400;
        font-size: 1rem;
      }
    }



    .searchBox {
      padding: 10px 25px 10px 51px;
      border: none;

      &:focus {
        background-color: $white;
      }

      &::placeholder {
        color: $silver;
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .search-icon {
      cursor: pointer;

      .icon-search:before {
        color: $bright-purple;
      }

      i {
        position: absolute;
        right: 10px;
        top: 15px;
      }
    }
  }
}