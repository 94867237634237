@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";
@import "../../../assets/scss/abstracts/colors.scss";

.my-services-popup-overlay {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  animation: slideUp 0.5s ease-out forwards;
  z-index: 9999;
  overflow: scroll;

  .complaince-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    .comp-main-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;
    }
    .footerPositionIcons {
      align-self: center;
    }
    .my-services-popup-left-title {
      color: #6b47f5;
      font-family: Tawuniya;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 20.8px */
      letter-spacing: -0.48px;
    }

    .my-services-popup-left-para {
      color: var(--Text-Black, c#1a1c1f);
      font-family: Tawuniya;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.28px;
    }
    .key-services {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .complaince-card {
        display: flex;
        flex: 0 1 calc(50% - 10px);
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 10px;

        .complaince-text {
          color: var(--Text-Black, #1a1c1f);
          font-family: Tawuniya;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          margin-bottom: 0;
        }
      }
    }
  }
  .comp-subMain-container {
    text-align: center;
    display: flex;
    width: 240px;
  }
  .comp-main-img {
    width: 100%;
  }
  .popup-content {
    background: white;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 30%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .my-services-popup-left-title {
      color: #6b47f5;
      font-weight: 700;
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.page-navbar-menu {
  margin: 0;
  position: fixed;
  left: -100%;
  width: 100%;
  top: 0;
  z-index: 15;
  box-shadow: 0px 50px 60px rgba(9, 30, 66, 0.15);
  border-radius: 0px 0px 20px 20px;
  background: $lightgrey-tw;
  padding: 20px 20px 0;
  transition: all 0.5s ease-out;
  overflow: auto;
  height: 100vh;
  overflow-y: auto;

  &.active {
    left: 0;
  }

  .mobile-menu-close {
    .close-menu {
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  .flag-lang {
    .btn {
      background: transparent !important;
    }

    .content {
      background: transparent;
      border-radius: 8px;
      padding: 6px 12px;
      border: none;

      span {
        margin: 0 6px;
        font-family: "tawuniya";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $eerie-black;
      }

      img {
        height: 1.75rem;
      }
    }
  }

  .dash_title {
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 140%;
    text-align: start;
    color: $primary-tw;
  }

  .mobile-menu-login-option {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .user-image {
      margin-right: 5px;

      img {
        object-fit: cover;
        object-position: center;
      }
    }

    .login-text {
      margin-right: 15px;
      font-weight: 700;
      font-size: fontSize(20);
      color: $eerie-black;

      label {
        cursor: pointer;
      }
    }

    .my-services {
      background-color: $primary-tw;
      color: $white;
      border-radius: 30px;
    }
  }

  .mobile-menu-search {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;

    input {
      background: $white;
      box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
      border-radius: 8px;
      border: 0;
      width: 100%;
      padding: 12px 23px;
      font-weight: 400;
      font-size: fontSize(16);
      padding-right: 50px;
    }

    img {
      position: absolute;
      right: 23px;
      top: 15px;
    }
  }

  .mobile-menu-box-content {
    background: $white;
    box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    .looking-for-text {
      color: $silver;
      font-weight: 500;
      font-size: fontSize(14);
      margin-bottom: 10px;
    }

    .mobile-menu-tabs {
      border-bottom: 1px solid $eerie-black-33;
      padding-bottom: 10px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      > div {
        flex: 0 0 calc(100% / 3);
        border-right: 1px solid $eerie-black-1a;
        padding: 0 10px;
        font-weight: 700;
        font-size: fontSize(16);
        color: $newLight-tw;
        cursor: pointer;

        &.active-tab-index {
          color: $primary-tw;
        }

        &:hover {
          color: $bright-purple;
        }

        &:last-child {
          padding-right: 0;
          border-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .menu-title-text {
      margin-bottom: 13px;
      color: $newLight-tw;
      font-weight: 700;
      font-size: fontSize(14);
    }

    .shared-menu {
      .right-side-menu-list .bold-menu {
        font-style: normal;
        font-weight: 800;
        font-size: 1rem;
        line-height: 140%;
        text-align: center;
        color: $roman-silver;

        img {
          height: 11px;
          width: 11.1px;
        }
      }
    }

    .right-side-menu-list {
      .bold-menu {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: fontSize(22);
        color: $newLight-tw;
        margin-bottom: 10px;
        text-decoration: none;
        font-style: normal;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.01em;
        color: $newLight-tw;
        cursor: pointer;

        &:hover {
          color: $bright-purple;
        }

        .count {
          margin: 0 0.5rem;
          color: $white;
          font-weight: 400;
          font-size: fontSize(12);
          background: $orange-tw;
          border-radius: 50px;
          padding: 2px 5px;
          min-width: 38px;
          text-align: center;
        }

        img {
          width: 14px;
          margin-left: 10px;
        }

        .link_icon {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
    .iframe-div-parent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 260px;
      position: relative;
      .iframe-div {
        position: absolute;
        top: -10px;
      }
    }

    .iframe-div-2 {
      height: 24px;
    }

    .sme-menu1 {
      .card {
        padding: 0.75rem;
        background: $primary-tw;
        border-radius: 8px;
        min-height: 120px;
        margin: 1rem 0 1.5rem;
        color: $white;
        font-size: 1rem;
        font-weight: 700;
        font-size: fontSize(14);
      }

      .shared-menu {
        .right-side-menu-list .bold-menu {
          font-style: normal;
          font-weight: 800;
          font-size: 1rem;
          line-height: 140%;
          text-align: center;
          color: $roman-silver;

          img {
            height: 11px;
            width: 11.1px;
          }
        }
      }

      .right-side-menu-list {
        .bold-menu {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: fontSize(22);
          color: $newLight-tw;
          margin-bottom: 10px;
          text-decoration: none;
          font-style: normal;
          font-weight: 800;
          font-size: 1.2rem;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.01em;
          color: $newLight-tw;

          &:hover {
            color: $bright-purple;
          }

          .count {
            margin: 0 0.5rem;
            color: $white;
            font-weight: 400;
            font-size: fontSize(12);
            background: $orange-tw;
            border-radius: 50px;
            padding: 2px 5px;
            min-width: 38px;
            text-align: center;
          }

          img {
            width: 14px;
            margin-left: 10px;
          }

          .link_icon {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .sme-menu1 {
        .card {
          padding: 0.75rem;
          background: $primary-tw;
          border-radius: 8px;
          min-height: 120px;
          margin: 1rem 0 1.5rem;
          color: $white;
          font-size: 1rem;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .stock_price_details {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;

            .price {
              font-size: 28px;
              line-height: 120%;
            }

            .description {
              font-weight: 400;
              font-size: 0.9rem;
              line-height: 140%;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-med-desktop {
    .menu-list-section-root {
      .menu-list-section {
        .menu-list-title {
          min-height: 48px;
        }

        .menu-details {
          min-height: 95px;
        }
      }
    }
  }

  @media screen and (max-width: 1399px) and (min-width: 1360px) {
    .menu-list-section-root {
      .menu-list-section {
        .menu-list-title {
          min-height: initial;
        }

        .menu-details {
          min-height: 71px;
        }
      }
    }
  }
}

.mobile-lang-modal {
  &.english {
    // font-family: "Bliss 2";
  }

  padding: 0px 16px;

  .my_profile_Topic {
    font-size: 1.2rem;
    font-weight: 800;
    margin-left: 0px;
    margin-top: 5px;
    color: $eerie-black;
    padding-top: 60px;
  }

  .my_profile_Topic_content {
    font-weight: 400;
    font-size: 0.9rem;
    margin-left: 0px;
    margin-top: 10px;
    color: $eerie-black;
  }

  .field-radiobutton {
    &.english {
      font-family: "tawuniya" !important;
    }

    &.arabic {
      font-family: "tawuniya" !important;
    }

    width: 100%;
    background: $white;
    margin-bottom: 10px;
    padding: 12px;
    box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
    border-radius: 4px;
    color: $newLight-tw;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse !important;
    border: 1px solid $gainsboro;
  }

  .field-radiobutton .p-radiobutton {
    float: right;
    color: black;
  }

  .field-radiobutton label {
    font-family: "tawuniya";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $newLight-tw;
    margin: 0;
  }

  .p-radiobutton .p-radiobutton-box {
    border: none;
    background: $gainsboro;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    // box-shadow: none !important;
    background: $princeton-orange !important;
    border-radius: 50% !important;
  }
}

.search_box_mob {
  background-color: $white;
  margin-bottom: 30px;

  img {
    cursor: pointer;
  }

  h5 {
    font-weight: 800;
    padding: 3px 0px 24px 0px;
    margin: 0;
  }

  span {
    font-size: 0.9rem;
    color: $newLight-tw;
  }

  h6 {
    font-weight: 800;
    margin-top: 20px;
  }

  .results_color {
    color: $princeton-orange;
    font-size: 0.9rem;
  }

  p {
    margin: 0;
    color: $eerie-black;
    font-size: 0.9rem;
  }

  span.full_results {
    font-size: 0.7rem;
  }

  img.right-arrow {
    height: 10px;
    width: 10px;
    cursor: pointer;
  }

  .popular_scroll {
    background-color: $lightgrey-tw;
    margin-bottom: 10px;
    border-radius: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 10px;
    min-width: 143px;
  }
}

.mobile-search-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 22px;
  background: $lightgrey-tw;

  img {
    cursor: pointer;
  }

  input {
    border: none;
    background: transparent;
    font-size: 1rem;
    color: $eerie-black;
    width: 75%;
  }

  .normal-search {
    width: 80%;

    .searchBox {
      padding-left: 10px;
    }
  }
}

.customer_services {
  p,
  h4,
  h5 {
    margin: 0px;
    padding: 0px;
  }

  .customer_service_items {
    .customer_service_item {
      h4 {
        color: $primary-tw;
      }

      p {
        color: $newLight-tw;
        margin: 6px 0;
      }

      h5 {
        padding: 8px 0;
      }

      img {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

.back_loyalty {
  display: flex;
  background: $anti-flash-white;
  color: $primary-tw;
  align-items: center;
  border-radius: 100px;
  font-weight: 800;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 1rem;
  width: 8rem;
  padding-block: 5px;
  cursor: pointer;

  p {
    margin-bottom: 0;
    padding-inline: 10px;
  }
}

.swipercards {
  .card {
    display: inline-block;
    position: relative;
    z-index: 9;
    width: 100%;
    height: 200px;
    margin-bottom: 0.6rem;
    border: none;
    border-radius: 8px;
    box-shadow: unset;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 12px;
      object-position: top center;
    }

    .top {
      img {
        width: 100%;
        object-fit: cover;
        object-position: top center;
      }

      p {
        margin: 0;
        font-weight: 800;
        font-size: 0.9rem;
      }

      h3 {
        font-style: italic;
      }
    }

    .bottom-slide {
      position: absolute;
      top: 0px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 10px 10px 15px;

      h4 {
        font-size: 1.1rem;
        font-weight: 400 !important;
        color: $white;
      }

      h6 {
        img {
          height: 22.55px;
          width: 28.97px;
          margin-right: 10px;
        }

        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    .bottom-slide.h4_pos {
      h4 {
        position: absolute;
        bottom: 0;
      }
    }

    .bottom-slide.ithra_prime {
      img {
        width: 50%;
      }
    }

    .bottom-slide.taj {
      img {
        width: 25%;
      }
    }
  }

  .card.two {
    .top span {
      font-size: 70px;
      font-weight: 900;
      margin: 0;

      sub {
        font-size: 30px;
        position: relative;
        left: -10px;
        bottom: 1px;
      }
    }
  }
}

.card-text {
  display: flex;
  justify-content: flex-start;
  font-size: 0.7rem;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

.row_wice_list {
  margin: 0px;
  padding: 0px 16px;
}

.language-alignment-right {
  .page-navbar-menu {
    .mobile-menu-close {
      // position: fixed;
      // top: 0;
      // left:0;
      // width: 100%;
      .close-menu img {
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }

  .customer_services {
    img {
      transform: rotate(180deg);
      padding-inline: 5px;
    }
  }
}

.right-arrow {
  transform: rotate(180deg);
}

.right-side-menu-list {
  .bold-menu {
    img {
      margin: 0 0.5rem;
    }

    .link_icon {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}

.back_loyalty {
  img {
    transform: rotate(180deg);
  }
}

.page-navbar-menu .mobile-menu-box-content .mobile-menu-tabs > div {
  border-left: 1px solid $eerie-black-1a;
  border-right: none;

  &:last-of-type {
    border-left: none;
  }
}

.mobile_menu_common_services {
  .view_all_services {
    img {
      height: 1rem;
      margin: 0 0.5rem;
    }
  }
}

.language-alignment-right {
  .bold-menu {
    img {
      transform: rotate(180deg);
    }
  }

  .back_loyalty {
    img {
      transform: rotate(180deg);
    }
  }
}
