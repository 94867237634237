@import "../../assets/scss/abstracts/abstracts";
@import "../../assets/scss/abstracts/mixins.scss";
@import "../../assets/scss/abstracts/variables.scss";
@import "../../assets/scss/abstracts/colors.scss";

.footerLastContainer {
  background-color: $dark-purple;
  padding: 40px 50px 50px 50px;

  .footerContentBox {
    .footerButtonPositon {
      position: relative;
      bottom: 65px;
      width: 100%;

      .footerButtonContainer {
        position: absolute;
        top: 0px;

        .backTopButton {
          background-color: $bright-purple;
          color: $white;
          padding: 8px;
          font-size: 1rem;
          font-weight: 400;
          border-radius: 100px;
          width: 100%;
          max-width: 150px;
          z-index: 10;
          white-space: nowrap;
        }

        .backTopButton-rtl {
          background-color: $bright-purple;
          color: $white;
          padding: 8px;
          font-size: 1rem;
          font-weight: 400;
          border-radius: 100px;
          width: 100%;
          max-width: 140px;
          z-index: 10;
        }
      }
    }

    .footerBottomLeftSpacing {
      display: flex;

      .copyrightText {
        margin-inline-start: 24px;
        align-self: center;
        font-weight: 400;
        font-size: 14px;
        color: $white;
        margin-bottom: 0;

        .footerLogo {
          width: 40px;
          height: 40px;
        }
      }
    }

    .footerBottomRightSpacing {
      padding-bottom: 20px;

      .footerPositionIcons {
        transform: translateX(0px);

        .classchanges {
          justify-content: flex-end;
        }
      }
    }

    .footerLining {
      border-bottom: 1px solid $lightgrey-tw;
    }
  }

  .footerLining {
    background-color: rgba(26, 28, 31, 0.1);
    padding: 0.3px;
  }

  .footerInlineText,
  .footerInlineText:hover {
    color: $white !important;
    cursor: pointer;
  }

  @media (min-width: 1050px) {
    transform: translateY(10px);
  }

  @media (min-width: 1070px) {
    transform: translateY(38px);
  }

  // @media (max-width: 1050px) {
  //   transform: translateY(10px);
  // }
}