@import "assets/scss/abstracts/variables.scss";

.welcome_kit_layout_main_wrapper {
    direction: ltr;

    * {
        font-family: "tawuniya" !important;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    .text_white {
        color: $white !important;
    }

    .welcome_kit_layout_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;

        .welcome_kit_layout_header_right {
            .welcome_kit_layout_header_right_lng {
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;

                p {
                    margin: 0 5px;
                }
            }
        }
    }

    .welcome_kit_footer {
        display: flex;
        background-color: $white;
        padding: 1.2rem 2rem;
        align-items: center;
        .welcome_kit_footer_cnt {
            padding: 0 1rem;

            p,
            h6 {
                font-weight: 400;
                line-height: 140%;
                color: $newLight-tw;
            }

            h6 {
                font-size: 14px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}

// language selector
.welcome_kit_layout_lng_menu {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    padding: 0.5rem 1rem;

    .welcome_kit_layout_lng_menu_items {
        display: flex;
        gap: 10px;
        margin-top: 8px;

        .welcome_kit_layout_lng_menu_item {
            display: flex;
            justify-content: space-between;
            min-width: 150px;
            cursor: pointer;
            padding: 8px;
            border-radius: 5px;
        }

        .welcome_kit_layout_lng_menu_item_txt {
            font-size: 1rem;
            font-weight: 500;
        }

        .welcome_kit_layout_lng_menu_item_subtxt {
            font-size: 0.65rem;
            font-weight: 400;
        }

        .welcome_kit_layout_lng_menu_item_active {
            background: $eerie-black;
            color: $white;
        }

        .welcome_kit_layout_lng_menu_item_inactive {
            background: $lightgrey-tw;
        }
    }
}

// arabic styles
.welcome_kit_layout_main_wrapper_rtl {
    direction: rtl;

    * {
        font-family: "tawuniya", sans-serif !important;
        text-align: right;
    }

    .ar_img {
        transform: scaleX(-1);
    }

    .welcome_kit_layout_header {
        .welcome_kit_layout_header_right {
            .welcome_kit_layout_header_right_lng {
                img {
                    transform: scaleX(-1);
                }
            }
        }
    }

    .welcome_kit_footer {
        .welcome_kit_footer_cnt {
            text-align: right;
        }
    }
}

// mobile styles
@media only screen and (max-width: 600px) {
    .welcome_kit_layout_main_wrapper {
        .welcome_kit_layout_header {
            padding: 1rem;
            background-color: $white;
            box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
            border-radius: 0px 0px 10px 10px;
            align-items: center;
        }

        .welcome_kit_footer {
            align-items: center;
            padding: 2rem;

            .welcome_kit_footer_cnt {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 5px;
            }
        }
    }

    // language selector
    .welcome_kit_layout_lng_menu {
        .welcome_kit_layout_lng_menu_items {
            flex-direction: column;
        }
    }
}