body {
    margin: 0 !important;
}

a.global_link:link {
    text-decoration: none;
    color: unset;
}

a.global_link:visited {
    text-decoration: none
}

a.global_link:active {
    text-decoration: none
}

a.global_link:hover {
    text-decoration: none;
    color: #ffffff;
}

.global_link {
    text-decoration: none;
    color: unset;
}

.global_link_flex {
    display: flex;
    align-items: flex-start
}

.global_link_flex_justify {
    flex: 1;
    justify-content: space-between;
}

.contact_us_thanks_model {
    margin-top: 26%;

}


.img-change {
    position: fixed;
    bottom: 211px;
    left: 50%;
    transform: translateX(-50%);
    height: 321px;
}

.Close {
    position: fixed;
    bottom: 430px;
    left: 7%;
    transform: translateX(-50%);

}


.text-bold {
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
    color: #000;
}

.p-accordion-header-link {
    text-align: start;
}


@media only screen and (max-width: 600px) {
    .rtl .tabWrapper {
        direction: rtl;
    }
}

a:hover {
    color: inherit !important;
    text-decoration: none;
}