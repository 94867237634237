$dark-purple: #312e52;
$light-grey: #d6d1ed;
$lilac: #8780f2;
$violet: #8f73f2;
$bright-purple: #6b47f5;
$grey: #a8a9cf;
$dark: #1a1c1f;
$silver-dark: #676f7c;
$text: #455560;
$neutral1: #f2f3f5;
$white: #fff;
$light-green: #5db364;
$dark-purple-hover:#423a67;

:export {
    dark-purple: $dark-purple;
    light-grey: $light-grey;
    lilac: $lilac;
    violet: $violet;
    bright-purple: $bright-purple;
    grey: $grey;
    dark: $dark;
    silver-dark: $silver-dark;
    text: $text;
    neutral1: $neutral1;
    white: $white;
    light-green: $light-green;
    dark-purple-hover: $dark-purple-hover;
}