@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic&display=swap");

.font-Lato {
	font-family: "tawuniya";
}

.font-Ibm {
	font-family: "tawuniya", sans-serif;
}

.font-aktiv {
	font-family: "tawuniya", sans-serif;
}

.font-co-heading {
	font-family: "tawuniya", sans-serif;
}

.font-tawuniya {
	font-family: "tawuniya", sans-serif;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf");
	font-style: normal;
	font-weight: 200;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Light.otf");
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Medium.otf");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Bold.otf");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	src: url("../../../assets/fonts/tawn/Tawuniya-Bold.otf");
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}

@font-face {
	font-family: "tawuniya";
	font-weight: 900;
	font-style: normal;
	src: url("../../../assets/fonts/tawn/Tawuniya-Heavy.otf") format("opentype");
}

@font-face {
	font-family: "tawuniya";
	font-weight: 700;
	src: url("../../../assets/fonts/tawn/Tawuniya-Bold.otf") format("opentype");
}

@font-face {
	font-family: "tawuniya";
	font-weight: 500;
	src: url("../../../assets/fonts/tawn/Tawuniya-Regular.otf") format("opentype");
}

@font-face {
	font-family: "tawuniya";
	font-weight: 400;
	src: url("../../../assets/fonts/tawn/Tawuniya-Medium.otf") format("opentype");
}

@font-face {
	font-family: "tawuniya";
	font-weight: 300;
	src: url("../../../assets/fonts/tawn/Tawuniya-Light.otf") format("opentype");
}

@font-face {
	font-family: "tawuniya";
	font-weight: 200;
	src: url("../../../assets/fonts/tawn/Tawuniya-ExtraLight.otf") format("opentype");
}