@import "assets/scss/abstracts/variables.scss";

.benefits-mobile-container {
	.top-header {
		color:$white;
		font-size: 12px;
	}
	.top-title {
		color:$white;
		font-size: 20px;
		font-weight: 800;
	}
	.benefits-mobile-top {
		height: 158px;
		background: $dark-sky-blue;
		border-bottom-right-radius: 20px;
		&-text-sec {
			display: flex;
			flex-direction: column;
			padding: 10px 20px;
		}
	}
	.benefits-mobile-bottom {
		.p-dropdown {
			border-radius: 10px;
			border:$white !important;
			box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		}
		.p-dropdown-trigger-icon {
			display: none;
		}
		.benefits-limit-text {
			font-size: 18px;
			font-weight: 800;
		}
		.med-benefits-container {
			.benefits-text-left {
				font-size: 16px;
				color: $newLight-tw;
				width: 15%;
				text-align: left;
			}
			.benefits-text-left-rtl {
				font-size: 16px;
				color: $newLight-tw;
				width: 15%;
				text-align: right;
			}
			.benefits-text-center {
				font-size: 16px;
				color: $newLight-tw;
				width: 55%;
			}
			.benefits-text-right {
				font-size: 16px;
				color: $primary-tw;
				font-weight: 800;
				width: 30%;
				text-align: right;
			}
			.benefits-text-right-rtl {
				font-size: 16px;
				color: $primary-tw;
				font-weight: 800;
				width: 30%;
				text-align: left;
			}
		}
		.limit-container {
			margin-bottom: 50px;
			.limit-left {
				font-size: 16px;
				color: $newLight-tw;
				width: 70%;
				text-align: left;
			}
			.limit-right {
				font-size: 16px;
				color: $primary-tw;
				font-weight: 800;
				width: 30%;
				text-align: right;
			}
			.limit-left-rtl {
				font-size: 16px;
				color: $newLight-tw;
				width: 70%;
				text-align: right;
			}
			.limit-right-rtl {
				font-size: 16px;
				color: $primary-tw;
				font-weight: 800;
				width: 30%;
				text-align: left;
			}
		}
	}
}
