@import "assets/scss/abstracts/variables.scss";

.mobile-lang-modal {
	.p-radiobutton {
		.p-radiobutton-box {
			&.p-highlight {
				background: $princeton-orange;
			}
		}
	}
}