@import "../scss/abstracts/abstracts";
@import "../scss/abstracts/mixins.scss";
@import "assets/scss/abstracts/variables.scss";

html body {
	font-family: "tawuniya";
	overflow-x: hidden;
	background-color: $lightgrey-tw;

	&.language-alignment-right {
		direction: rtl;
		text-align: right;
	}
}

body::-webkit-scrollbar {
	display: none;
}

:is(h1, h2, h3, h4, h5, h6, p, span, div, li) {
	font-family: "tawuniya";
}

.font-color-a {
	color: $orange-tw;
}

button {
	&.btn {
		&.primary-btn {
			background: $primary-color;
			border-radius: 4px;
			font-weight: 500;
			border: 1px solid transparent;
			color: $white;
		}

		&.viewButtonHeader {
			background: transparent;
			border-radius: 4px;
			border: 1px solid $grey-ca;
			color: $primary-color;
			width: 150px;
			padding: 5px;
			font-weight: 500;
		}

		&.viewButtonColorHeader {
			background: $primary-color;
			border: 1px solid transparent;
			border-radius: 4px;
			color: $white;
			width: 150px;
			padding: 5px;
			font-weight: 500;
		}
	}
}

.word-break-normal {
	word-break: normal;
}

.btn {
	height: 45px;
}

.btn:hover {
	color: none !important;
}

.btn:focus,
.btn:active {
	outline: none !important;
	box-shadow: none !important;
}

.form-control:focus {
	border-color: $white !important;
	outline: 0;
	background-color: $white !important;
	box-shadow: none;
}

.text-black {
	color: $eerie-black;
}

.cursor-pointer {
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.text-underline {
	text-decoration: underline;
}

.modal-content {
	border: none;
	padding: 0;
	height: 100%;
}

.Select__dropdown-indicator {
	.icon-up {
		padding-bottom: 2px;
	}
}

.form-control {
	&:disabled {
		border-radius: 4px;
		font-size: fontSize(14px);
		line-height: 18px;
		color: $text-black-1a;
		opacity: 0.9;
		background-color: $white;
		cursor: not-allowed;
	}
}

button:focus {
	outline: none;
}

.border-radius-50 {
	border-radius: 50%;
}

.word-wrap {
	word-wrap: break-word;
}

.border-top {
	border-top: 1px solid $silver-sand !important;
}

.landingPageContainer {
	background-color: $lightgrey-tw;
}

.landingPageContainerBlur {
	background-color: $lightgrey-tw;
	filter: blur(7px);
}

.mainContainer {
	width: 100%;
}

.mainContainer.container-fluid.vh-100.pr-0 {
	padding-left: 7px;
}

.rtl .mainContainer.container-fluid.vh-100.pr-0 {
	margin-right: 10px !important;
}

.paddingContainer {
	padding: 0px 40px 0px 40px;
	background-color: $lightgrey-tw;
}

.authBgContainer {
	// background-image: url("../../assets/svg/authBg.svg");
	background-size: 85.3rem;
	background-repeat: no-repeat;
	background-position: 60% 104%;
	width: 100%;

	@media screen and (min-width: 1800px) {
		background-size: 188.3rem;
		background-position: 100% 70%;
	}
}

.language-alignment-right {
	.authBgContainer {
		// background-image: url("../../assets/svg/authBg.svg");
		background-size: 85.3rem;
		background-repeat: no-repeat;
		width: 100%;
		background-position: 1200% 70%;

		@media screen and (min-width: 1600px) {
			background-position: -62% 100%;
		}

		@media screen and (max-width: 1310px) {
			background-size: 60rem;
			background-position: -660% 110%;
		}

		@media screen and (min-width: 1800px) {
			background-size: 188.3rem;
		}

		@media screen and (min-width: 1800px) {
			background-position: 180% 70%;
		}

		@media screen and (max-width: 1599px) and (min-width: 1487px) {
			background-position: -290% 70% !important;
		}

		@media screen and (max-width: 1486px) and (min-width: 1432px) {
			background-position: -600% 70% !important;
		}

		@media screen and (max-width: 1431px) and (min-width: 1396px) {
			background-position: -1000% 70% !important;
		}
	}
}

.tawuniyaCanvasContainer {
	background-color: $white;
}

.card-Language-Layout {
	position: absolute;
	top: 10px;
	right: 340px;
	// bottom: 0;
	z-index: 10;
}

.card-Language-Layout-forInsurance {
	position: absolute;
	right: 340px;
	z-index: 10;
}

.card-Support-Layout {
	position: absolute;
	top: 10px;
	right: 180px;
	bottom: 0;
	z-index: 10;
}

.card-Support-Layout-forInsurance {
	position: absolute;
	top: -70px;
	right: 180px;
	z-index: 9999;
}

.card-GetStarted-Layout {
	position: absolute;
	top: 10px;
	right: 60px;
	bottom: 0;
	z-index: 10;
}

.card-GetStarted-Layout-forInsurance {
	position: absolute;
	top: -70px;
	right: 60px;
	z-index: 10;
}

.MoreProduct-Card-Layout {
	display: flex;
	justify-content: center;
}

.blurBackground {
	filter: blur(5px);
}

#hideSubClass {
	display: none;
}

.sticky {
	position: sticky !important;
	top: 0px !important;
	z-index: 10 !important;
	padding: 0px 30px 0px 30px;
	background-color: $lightgrey-tw;
}

.insuranceNavBg {
	background-color: $white;
}

.unset-position {
	position: unset !important;
}

.space-higlight {
	padding-right: 25px;
}

.space-normal {
	padding-right: 50px;
}

.dashboard-RightContainer {
	.flexDashboardOne {
		width: 3%;
	}

	.flexDashboardTwo {
		width: 70%;
		border-radius: 0px 0px 50px 0px;
		padding: 0px 25px 0px 40px;

		.footer-road-container {
			.road-copyrights {
				color: $light-tw;
			}
		}
	}

	.flexDashboardThree {
		width: 28%;
		background-color: $white;
	}
}

.dashboard-notify-numbers {
	background-color: $lightgrey-tw;
	color: $eerie-black;
	border-radius: 100px;
	padding: 6px 15px 6px 15px;
	position: relative;
	bottom: 3px;
}

.language-alignment-right {
	.card-Language-Layout {
		right: auto;
		left: 340px;
	}

	.card-Support-Layout {
		left: 180px;
		right: auto;
	}

	.card-GetStarted-Layout {
		left: 60px;
		right: auto;
	}
}

.google-map-service-dashboard {
	height: 500px;
}

@include media-breakpoint-med-desktop {
	.dashboard-RightContainer {
		.flexDashboardTwo {
			padding: 0px 25px 0px 40px;
		}
	}
}

@include media-breakpoint-tab-port {
	.dashboard-RightContainer {
		.flexDashboardTwo {
			padding: 0px 20px 0px 30px;
		}
	}
}

@include media-breakpoint-tab-port-med-only {
	.paddingContainer {
		padding: 0px 10px 0px 10px;
	}
}

@include media-breakpoint-tab-land {
	.paddingContainer {
		padding: 0px 10px 0px 10px;
	}
}

@media only screen and (min-width: 1000px) {
	html {
		zoom: 1;
	}
}

.rtl .dashboard-RightContainer .flexDashboardTwo {
	text-align: right;
}

.eligibile-map {
	position: relative;
}

.eligibile-map .map-text {
	margin: 0;
	line-height: 0;
}

.eligibile-map img {
	width: 20px;
}

.mapaddress {
	position: absolute;
	background: $white;
	box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
	border-radius: 8px;
	padding: 15px;
	bottom: 15px;
	left: -75px;
	width: 170px;
	display: none;
}

.mapaddress:after {
	content: "";
	position: absolute;
	left: 39%;
	top: 100%;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid $white;
	clear: both;
}

.mapaddress h3 {
	font-weight: 800;
	font-size: 1.25rem;
	color: $primary-tw;
}

.mapaddress p {
	font-weight: 400;
	font-size: 0.875rem;
	margin-bottom: 10px;
}

.mapaddress .map-direction a {
	font-weight: 800;
	font-size: 1rem;
	color: $newLight-tw;
	text-decoration: none;
}

.mapaddress .map-direction img {
	margin: 0 5px;
}

.mapactive {
	display: block;
}

.flipped-element {
	transform: rotate(180deg);
}

.datePickerStyle {
	position: relative;

	input {
		padding: 16px 52px;
		background-color: $white;
		box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		border-radius: 4px;
		border: none;
		width: 100%;
	}

	.dateIconBox {
		position: absolute;
		display: flex;
		left: 25px;
		top: 30px;
		z-index: 2;
	}

	.selectIcon {
		position: absolute;
		display: flex;
		left: 0;
		top: 10px;
		z-index: 2;
	}
}

.box-canvas {
	width: 100%;
	max-width: 470px;
	background-color: $white;
	padding: 20px;
	border-radius: 16px 0px 0px 16px;
	box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
	min-height: 100vh;
	position: fixed;
	right: 0px;
	z-index: 3;
}

.rtl .mr-2,
.rtl .mx-2 {
	margin-right: 0 !important;
	margin-left: 0.5rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
	padding-right: 1rem !important;
	padding-left: 0rem !important;
}

.sec-title {
	max-width: 836px;
	margin: auto;

	h6,
	.sec-title-head {
		font-weight: 800;
		font-size: 34px;
		line-height: 120%;
		text-align: center;
		letter-spacing: -0.02em;
		color: $eerie-black;
	}

	p,
	.sec-title-para {
		font-weight: 400;
		font-size: 18px;
		line-height: 140%;
		display: flex;
		align-items: center;
		text-align: center;
		color: $newLight-tw;
	}
}
