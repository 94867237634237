@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";

.navbar {
	height: 82px;
	background-color: $lightgrey-tw;
	padding: 0px 40px 0px 40px !important;
	z-index: 1000;

	.avatar {
		cursor: pointer;
	}

	.avatar-dropdown {
		background: $white;
		padding: 15px 80px 15px 15px;
		position: absolute;
		right: 20px;
		top: 74px;
		z-index: 1000000;

		.avatar-welome {
			font-size: 12px;
			font-weight: 700;
		}

		.avatar-logout {
			margin-top: 15px;

			.logout-text {
				color: $newLight-tw;
				font-size: 14px;
				cursor: pointer;
				font-weight: 500;
			}
		}
	}

	.avatar-dropdown-rtl {
		background: $white;
		padding: 15px 15px 15px 80px;
		position: absolute;
		left: 10px;
		top: 74px;
		z-index: 1000000;

		.avatar-welome {
			font-size: 12px;
			font-weight: 700;
		}

		.avatar-logout {
			margin-top: 15px;

			.logout-text {
				color: $newLight-tw;
				font-size: 14px;
				cursor: pointer;
				font-weight: 500;
			}
		}
	}

	.hamburgerIcon {
		transform: translateY(7px);
		cursor: pointer;
	}

	.tawuniyaLogo {
		transform: translateY(2px);
	}

	.navLink-Text {
		color: $light-tw;
		cursor: pointer;
		transform: translateY(7px);

		&:hover {
			color: $orange-tw;
		}
	}

	.getStartBtn {
		background-color: $orange-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.emergencyBtn {
		background-color: $primary-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.redEmerencyBtn {
		background-color: $lightRed-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.currently-selected-language {
		display: flex;
		align-items: center;
		color: $eerie-black;
		font-weight: 400;
		font-size: fontSize(14);
		line-height: 140%;

		img {
			margin-left: 6px;
		}
	}

	.languageBtnIcon {
		cursor: pointer;
	}

	.searchBtnIcon {
		transition-delay: 20s;
	}

	.searchBtnIcon,
	.modeBtnIcon,
	.notifyBtnIcon {
		cursor: pointer;
		transform: translateY(4px);
	}

	.headerSearch {
		border-radius: 30px;
		width: 270px;
		border: none;
		background-color: $white;
		box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			0% {
				width: 0px;
			}

			100% {
				width: 270px;
			}
		}
	}

	.close-headerSearch {
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			100% {
				width: 270px;
			}

			0% {
				width: 0px;
			}
		}
	}

	.searchResultBox {
		background-color: $white;
		box-shadow: 0px 35px 60px rgba(9, 30, 66, 0.15);
		border-radius: 12px;
		padding: 15px;
		position: fixed;
		width: 100%;
		max-width: 270px;
		z-index: 10000;

		.search-ResultTitle {
			color: $eerie-black;
		}

		.search-ResultPara {
			color: $newLight-tw;

			.search-ResultName {
				color: $orange-tw;
			}
		}

		.resultContainer {
			border-bottom: 1px solid rgba(26, 28, 31, 0.1);

			.result_Name {
				color: $eerie-black;
			}

			.result_Title {
				color: $primary-tw;
			}
		}

		.fullResultLink {
			color: $newLight-tw;
		}

		.fullResult-arrow {
			width: 10px;
			height: 10px;
		}

		.mostUsed-Title {
			color: $eerie-black;
		}

		.result-suggestion {
			background-color: $lightgrey-tw;
			color: $newLight-tw;
			border-radius: 100px;
			padding: 5px 10px 5px 10px;
		}
	}
}

.navbar-white {
	height: 82px;
	padding: 0px 40px 0px 40px !important;
	z-index: 1000;
	background: $white;

	.avatar {
		cursor: pointer;
	}

	.avatar-dropdown {
		background: $white;
		padding: 15px 80px 15px 15px;
		position: absolute;
		right: 20px;
		top: 74px;
		z-index: 1000000;

		.avatar-welome {
			font-size: 12px;
			font-weight: 700;
		}

		.avatar-logout {
			margin-top: 15px;

			.logout-text {
				color: $newLight-tw;
				font-size: 14px;
				cursor: pointer;
				font-weight: 500;
			}
		}
	}

	.avatar-dropdown-rtl {
		background: $white;
		padding: 15px 15px 15px 80px;
		position: absolute;
		left: 10px;
		top: 74px;
		z-index: 1000000;

		.avatar-welome {
			font-size: 12px;
			font-weight: 700;
		}

		.avatar-logout {
			margin-top: 15px;

			.logout-text {
				color: $newLight-tw;
				font-size: 14px;
				cursor: pointer;
				font-weight: 500;
			}
		}
	}

	.hamburgerIcon {
		transform: translateY(7px);
		cursor: pointer;
	}

	.tawuniyaLogo {
		transform: translateY(2px);
	}

	.navLink-Text {
		color: $light-tw;
		cursor: pointer;
		transform: translateY(7px);

		&:hover {
			color: $orange-tw;
		}
	}

	.getStartBtn {
		background-color: $orange-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.emergencyBtn {
		background-color: $primary-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.redEmerencyBtn {
		background-color: $lightRed-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.currently-selected-language {
		display: flex;
		align-items: center;
		color: $eerie-black;
		font-weight: 400;
		font-size: fontSize(14);
		line-height: 140%;
		cursor: pointer;

		img {
			margin-left: 6px;
		}
	}

	.languageBtnIcon {
		cursor: pointer;
	}

	.searchBtnIcon {
		transition-delay: 20s;
	}

	.searchBtnIcon,
	.modeBtnIcon,
	.notifyBtnIcon {
		cursor: pointer;
		transform: translateY(4px);
	}

	.headerSearch {
		border-radius: 30px;
		width: 270px;
		border: none;
		background-color: $white;
		box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			0% {
				width: 0px;
			}

			100% {
				width: 270px;
			}
		}
	}

	.close-headerSearch {
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			100% {
				width: 270px;
			}

			0% {
				width: 0px;
			}
		}
	}

	.searchResultBox {
		background-color: $white;
		box-shadow: 0px 35px 60px rgba(9, 30, 66, 0.15);
		border-radius: 12px;
		padding: 15px;
		position: fixed;
		width: 100%;
		max-width: 270px;
		z-index: 10000;

		.search-ResultTitle {
			color: $eerie-black;
		}

		.search-ResultPara {
			color: $newLight-tw;

			.search-ResultName {
				color: $orange-tw;
			}
		}

		.resultContainer {
			border-bottom: 1px solid rgba(26, 28, 31, 0.1);

			.result_Name {
				color: $eerie-black;
			}

			.result_Title {
				color: $primary-tw;
			}
		}

		.fullResultLink {
			color: $newLight-tw;
		}

		.fullResult-arrow {
			width: 10px;
			height: 10px;
		}

		.mostUsed-Title {
			color: $eerie-black;
		}

		.result-suggestion {
			background-color: $lightgrey-tw;
			color: $newLight-tw;
			border-radius: 100px;
			padding: 5px 10px 5px 10px;
		}
	}
}

.navbar-light {
	height: 82px;
	background-color: $white;
	padding: 0px 30px 0px 30px !important;
	z-index: 1000;

	.hamburgerIcon {
		transform: translateY(7px);
		cursor: pointer;
	}

	.tawuniyaLogo {
		transform: translateY(2px);
	}

	.navLink-Text {
		color: $light-tw;
		cursor: pointer;
		transform: translateY(7px);

		&:hover {
			color: $orange-tw;
		}
	}

	.getStartBtn {
		background-color: $orange-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.emergencyBtn {
		background-color: $primary-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.redEmerencyBtn {
		background-color: $lightRed-tw;
		border-radius: 100px;
		font-size: 1rem;
		font-weight: 400;
		color: $white;
		width: 100%;
		min-width: 120px;
	}

	.currently-selected-language {
		display: flex;
		align-items: center;
		color: $eerie-black;
		font-weight: 400;
		font-size: fontSize(14);
		line-height: 140%;
		cursor: pointer;

		img {
			margin-left: 6px;
		}
	}

	.languageBtnIcon {
		cursor: pointer;
	}

	.searchBtnIcon {
		transition-delay: 20s;
	}

	.searchBtnIcon,
	.modeBtnIcon,
	.notifyBtnIcon {
		cursor: pointer;
		transform: translateY(4px);
	}

	.headerSearch {
		border-radius: 30px;
		width: 270px;
		border: none;
		background-color: $white;
		box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			0% {
				width: 0px;
			}

			100% {
				width: 270px;
			}
		}
	}

	.close-headerSearch {
		animation: animateSearch 0.5s ease-in-out;

		@keyframes animateSearch {
			100% {
				width: 270px;
			}

			0% {
				width: 0px;
			}
		}
	}

	.searchResultBox {
		background-color: $white;
		box-shadow: 0px 35px 60px rgba(9, 30, 66, 0.15);
		border-radius: 12px;
		padding: 15px;
		position: fixed;
		width: 100%;
		max-width: 270px;
		z-index: 10000;

		.search-ResultTitle {
			color: $eerie-black;
		}

		.search-ResultPara {
			color: $newLight-tw;

			.search-ResultName {
				color: $orange-tw;
			}
		}

		.resultContainer {
			border-bottom: 1px solid rgba(26, 28, 31, 0.1);

			.result_Name {
				color: $eerie-black;
			}

			.result_Title {
				color: $primary-tw;
			}
		}

		.fullResultLink {
			color: $newLight-tw;
		}

		.fullResult-arrow {
			width: 10px;
			height: 10px;
		}

		.mostUsed-Title {
			color: $eerie-black;
		}

		.result-suggestion {
			background-color: $lightgrey-tw;
			color: $newLight-tw;
			border-radius: 100px;
			padding: 5px 10px 5px 10px;
		}
	}
}

.language-alignment-right {
	.navbar {
		>.d-flex {
			>.mr-3 {
				margin-right: 0 !important;
				margin-left: 1rem;
			}
		}

		.getStartBtn {
			margin-left: 1rem;
			width: calc(100% - 1rem);
		}

		.currently-selected-language {
			cursor: pointer;

			img {
				transform: rotateY(180deg);
				margin-right: 6px;
				margin-left: 0;
			}
		}
	}
}

@include media-breakpoint-mobile-port {
	#navbarDesktop {
		display: none;
	}
}

@include media-breakpoint-desktop-port {
	.navbarMobile {
		display: none;
	}
}

@include media-breakpoint-tab-land {
	.navbar {
		padding: 0px 6px 0px 6px !important;
	}
}