
@import "assets/scss/abstracts/variables.scss";

.mobile_sticky_bottom_menu {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;

	.loader-tawuniya {
		position: fixed;
		width: 100%;
		height: 100%;
		background: $dark-charcoal;
		z-index: 99999;

		img {
			position: absolute;
			top: 40%;
			left: 45%;
			margin: auto;
			display: block;
		}
	}

	a {
		cursor: pointer;
	}

	@media only screen and (min-width: 320px) and (max-width: 350px) {
		.contact_us_container {
			.contact_header {
				p {
					font-weight: 400;
					font-size: 0.75rem;
					color: $newLight-tw;
					margin: 0px;

					span {
						color: $princeton-orange;
					}
				}
			}

			.map_section .map_container .direct_location_section {
				left: 10px;
			}
		}
	}

	.third-footer {
		background-image: url("../../assets/svg/menu/BG-bk.svg");
		background-repeat: no-repeat;
		background-position: center;
		// background-size: 500px;
		height: 136px;
		border: none !important;
		box-shadow: 0px 4px 10px $maastricht-blue-0f
	}

	.searchBtnIcon,
	.modeBtnIcon,
	.notifyBtnIcon {
		cursor: pointer;
		transform: translateY(4px);
	}

	.pr-lg-2 {
		position: ABSOLUTE;
		margin: 14px 34px;
	}

	.footer-icons {
		position: relative;
		top: 50%;

		.footer_body {
			display: flex;
			justify-content: space-around;

			p {

				height: 12px;
				font-style: normal;
				font-weight: 400;
				font-size: 0.75rem;
				line-height: 28px;
				text-align: center;
				color: $primary-tw;
			}

			.active_menu {
				color: $primary-tw;
			}

			.greenball-placing {
				position: relative;
				left: 0px;
				top: -64px;
				margin-left: -14px;
			}

			.greenball-placing-ar {
				position: relative;
				left: auto;
				right: -15px;
				top: -64px;
			}

			.greenball-dash-placing {
				position: relative;
				left: 0px;
				top: -64px;
				margin-left: -43px;
			}

			.greenball-placing-dash-ar {
				position: relative;
				left: auto;
				right: 0px;
				top: -64px;
			}
		}
	}



	.contact_us_thanks_model {
		margin-top: 48%;
		font-family: 'tawuniya' !important;

		img {
			position: fixed;
			bottom: 242px;
			left: 50%;
			transform: translateX(-50%);
			height: 321px;
		}

		.contact_us_thanks {
			font-weight: 800;
			font-size: 1.2rem;
			color: $eerie-black;
			justify-content: center;
			display: flex;
			text-align: center;
			width: 100%;
			font-family: 'tawuniya' !important;
		}

		p {
			font-weight: 400;
			font-size: 1rem;
			align-items: center;
			text-align: center;
			color: $newLight-tw;
			margin-top: 16px;

			.contact_us_help {
				color: $princeton-orange;
				margin-left: 4px;
			}
		}

		.contact_us_close {
			font-weight: 400;
			font-size: 1rem;
			color: $princeton-orange;
			margin-top: 35px;
			justify-content: center;
			display: flex;
		}
	}

	.woman-block {
		margin-bottom: 15rem;
		display: flex;
		justify-content: center;
		padding-top: 2.5rem;
		position: relative;

		img {
			height: 180px;
		}
	}

	@media only screen and (min-width: 320px) and (max-width: 350px) {
		.contact_us {
			.contact_header {
				p {
					font-weight: 400;
					font-size: 0.75rem;
					color: $newLight-tw;
					margin: 0px;

					span {
						color: $princeton-orange;
					}
				}
			}

			.map_section .map_container .direct_location_section {
				left: 10px;
			}
		}
	}
}

.hide_mobile_sticky_bottom_menu {
	display: none;
}


.language-alignment-right {
	* {
		font-family: 'tawuniya';
	}

	&.mobile_sticky_bottom_menu {
		.footer-icons {
			.footer_body {


				.greenball-placing {
					position: relative;
					left: auto;
					right: 8px;
					top: -55px;
				}

				.greenball-placing-ar {
					position: relative;
					left: auto;
					right: 8px;
					top: -55px;
				}

				.greenball-placing-dash {
					position: relative;
					left: auto;
					right: 8px;
					top: -55px;
				}

				.greenball-placing-dash-ar {
					position: relative;
					left: auto;
					right: 8px;
					top: -55px;
				}
			}

		}
	}

	// .icon {
	//     // padding: 0px 8px 0 13px !important;
	// }


	.greenball-placing {
		position: relative;
		left: auto;
		right: 8px;
		top: -55px;
	}
}


// .icon {
//     // padding: 0px 8px 0 13px !important;
// }

.recentFeedContainer .recent-main-container .recentContainer .recentFeed-SubForm .cameraIcon {
	right: auto;
	left: 1.5rem;
}

.landingHelperSection img.search {
	right: auto;
	left: 16px;
}

.isLanding.active.header_mobile_sticky img.toggle-logo {

	left: 16px;
}

.recentFeedContainer .recent-main-container .recentContainer .recentFeed-navpillFlow .pillBox .recentFeedHighlightPill {
	padding: 3px 8px 3px 8px;
}


.loader-tawuniya {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $dark-charcoal;
	z-index: 99999;

	img {
		position: absolute;
		top: 40%;
		left: 45%;
		margin: auto;
		display: block;
	}
}

.content-container {
	min-height: 67vh;
}



.landingContainer_hide {
	display: none;
}

.padding_right_zero {
	padding-right: 0px !important;
}

.auth-header {
	.header-radius {
		padding: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		overflow: hidden;
	}
}

.error-mobile-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .error-mobile-navbar {
    width: 100%;
    flex: 0 0 auto; /* Non-flexible, it will stay fixed */
  }

  .error-mobile-content {
    flex: 1 0 auto;
  }

  .error-mobile-footer {
    flex: 0 0 auto; 
  }
}