.smart-app-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #FFFFFF;
    border-bottom: .1px solid #eee;
    text-align: start;
    box-sizing: border-box;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-attachment: scroll;
    width: 100%;
    z-index: 100;
    position: sticky;
    top: 0;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 6px;
        display: flex;
        align-items: center;
        color: #1A1C1F;
        margin-top: 22px;
    }

    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #8A8A8D;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.smart-banner-logo {
    height: 50px;
    width: 50px;
    // height: 31px;
    // width: 31px;
}

.smart-app-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    flex: 1;
    align-self: end;
}

.open-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-items: center;
    gap: 15px;

    span {
        background: darkgrey;
        border-radius: 50%;
        display: inline-block;
        width: 24px;
        color: white;
        text-align: center;
        cursor: pointer;
    }

    button {
        border-radius: 5px;
        padding: 8px 16px;
        background-color: #6B47F5;
        color: white;
    }
}

.open-btn a {
    padding: 8px 14px;
    background: #3478F6;
    border-radius: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF !important;
    cursor: pointer;
    text-decoration: none;
}

// body.language-alignment-right {
//     .smart-app-banner {
//         flex-direction: row-reverse;
//     }

//     .change-rtl {
//         flex-direction: row-reverse;
//     }
// }
body.popup-open {
    padding-bottom: 80px;
}