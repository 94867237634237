@import "../../../assets/scss/abstracts/abstracts";

.cardContainer {
  width: 100%;
  background-color: $white !important;
  padding: 5px 18px 5px 18px;
  border-radius: 12px !important;
  border: 1px solid transparent;
  box-shadow: 0 7px 10px rgba(9, 30, 66, 0.05) !important;
}
