@import "assets/scss/abstracts/font";
@import "assets/scss/abstracts/colors.scss";

.product-container {
  list-style: none;
  transition: 0.3s all ease-in-out;
  flex: 0 0 calc(30.333333% - 24px);

  &.md {
    flex: 0 0 100%;

    &.placeholder {
      display: none;
    }
  }

  .product-card {
    background: $white;
    box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
    border-radius: 12px;
    min-height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .MuiCardContent-root {
      padding: 24px;
    }

    .MuiCardActions-root {
      border-top: 1px solid rgba(26, 28, 31, 0.1);
      padding: 16px 24px;
    }

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: -0.01em;
      color: $dark;
    }

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 1.1rem;
      color: $text;
    }

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 0.7rem;
      line-height: 12px;
      text-transform: none !important;
      color: $text;
      align-items: center;
      justify-content: start;
      display: flex;

      .icon-container {
        width: 32px;
        height: 32px;
        background: #6b47f5;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .icon {
          width: 16px;
          height: 16px;
          padding: 0;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      color: rgba(69, 85, 96, 0.7);
    }

    .cardActions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      Button {
        &.explore {
          font-style: normal;
          font-weight: 400;
          font-size: 0.9rem;
          text-transform: none !important;
          color: $bright-purple;

          svg {
            fill: $bright-purple;
            margin: 0 10px;
            width: 15px;
          }
        }

        &.buy {
          font-style: normal;
          font-weight: 300;
          font-size: 0.8rem;
          line-height: 14px;
          color: $bright-purple;
          text-transform: none;

          border: 1.5px solid $bright-purple;
          border-radius: 6px;
          padding: 8px 10px;

          &:hover {
            background-color: $bright-purple;
            color: $white;
          }
        }

        &.MuiButton-textPrimary:hover {
          background: unset;
        }
      }
    }
  }
}

.language-alignment-right {
  .explore {
    svg {
      transform: rotate(180deg);
    }
  }

  .icon-container {
    margin-left: 8px;
    margin-right: 0;
  }
}