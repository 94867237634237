@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";
@import "assets/scss/abstracts/variables.scss";


.recentPolicyContainerVAS {
	margin: 15px;
	padding: 10px 15px 0px 15px;
	background-color: $white;
	box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
	border-radius: 8px;
	.timerText {
		color: $eerie-black;
	}
	.rpc-progressLabel {
		color: $primary-tw;
		background-color: $anti-flash-white;
		padding: 2px 10px 2px 10px;
		border-radius: 100px;
		font-size: 11px;
		&.completed {
			background: $honeydew;
			color: $lightGreen-tw;
		}
	}
	.status-with-file-name {
		display: flex;
		align-items: center;
		.file-name {
			color: $eerie-black;
			margin-right: 15px;
			display: flex;
			align-items: center;
			img {
				margin-right: 7px;
			}
		}
	}
	.rpc-sub-container {
		background-color: $lightgrey-tw;
		padding: 10px 10px 8px 10px;
		border-radius: 4px;
		margin-top: 10px;
		.policyIcon {
			height: 2.5rem;
		}

		.policytitle {
			color: $newLight-tw;
		}

		.rpc-userName {
			color: $eerie-black;
		}
	}
	.getHelpTxt,
	.vieDetailsTxt {
		color: $eerie-black;
	}
	&.grey-bg {
		background-color: $lightgrey-tw;
		box-shadow: none;
		.rpc-sub-container {
			background: $white;
		}
	}
}

@include media-breakpoint-tab-port {
	.recentPolicyContainer {
		margin-top: 15px;
		padding: 10px 15px 0px 15px;
		background-color: $white;
		box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
		border-radius: 8px;
		.timerText {
			color: $eerie-black;
		}
		.rpc-progressLabel {
			font-size: 0.6rem;
		}
		.rpc-sub-container {
			background-color: $lightgrey-tw;
			padding: 10px 10px 8px 10px;
			border-radius: 4px;
			margin-top: 10px;
			.policyIcon {
				height: 2.5rem;
			}

			.policytitle {
				color: $newLight-tw;
			}

			.rpc-userName {
				color: $eerie-black;
			}
		}
		.getHelpTxt,
		.vieDetailsTxt {
			color: $eerie-black;
		}
	}
}
