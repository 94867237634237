@import "assets/scss/abstracts/variables.scss";

.header_close_nav_container {
	background: $dark-sky-blue;
	border-radius: 0px 0px 20px 0px;
	color: $white;
	padding: 20px 16px 55px 16px;

	img {
		margin-bottom: 18px;
	}

	p {
		font-weight: 400;
		font-size: 0.7rem;
		margin-bottom: 6px;
	}

	h6 {
		font-weight: 800;
		font-size: 1.2rem;
	}
	.header_image_position {
		margin-left: 20rem;
	}
}

.is-bottom.header_close_nav_container {
	position: relative;
	height: 125px;
}

.is-bottom.header_close_nav_container {
	p {
		bottom: 45px;
		padding: 0;
	}
	h6 {
		bottom: 12px;
	}
}
