$primary-color: #08316d;
$secondary-color: #52575c;
$gold-d8: #d8af49;
$green-18: #189c5c;

// ** White Color Variants
$white: #fff;
$white-cf4: '#{$white}cf4';
$white-fff5e: '#{$white}fff5e';
$white-5e5: '#{$white}5e5';
$white-fff1a: '#{$white}fff1a';
$white-fffcf: '#{$white}fffcf';
$white-0f0: '#{$white}0f0';
$white-fff5e: '#{$white}fff5e';


// ** Black Color Variants
$eerie-black: #1a1c1f;
$eerie-black-1a: '#{$eerie-black}1a';
$eerie-black-33: '#{$eerie-black}33';
$eerie-black-10: '#{$eerie-black}10';
$eerie-black-30: '#{$eerie-black}30';
$eerie-black-40: '#{$eerie-black}40';

$black: #000000;
$black-33: '#{$black}33';
$black-ed: '#{$black}ed';
$black-4d: '#{$black}4d';
$black-66: '#{$black}66';
$black-33: '#{$black}33';
$black-80: '#{$black}80';


$text-black-1a: #1a202c;
$black-25: #25282b;
$grey-ca: #cacccf;
$light-tw: #4c565c;
$grey-67: #676b70;
$yellow-C1: #c18f13;
$darkOrange-tw: #be5e00;
$lightgrey-tw: #f2f3f5;
$lightBlack-tw: #262626;
$lightRed-tw: #c4314b;
$lightGreen-tw: #237b4b;
$lightWhite-tw: #b3b9c3;
$claret: #761D2D;

$newLight-tw: #455560;
$newLight-tw-b3: '#{$newLight-tw}b3';

$orange-tw: #6B47F5;
$orange-tw-33: '#{$orange-tw}33';

$lightOrange-tw: #f5ac66;
$lightOrange-tw-cf: '#{$lightOrange-tw}cf';

$silver: #9aa2af;
$silver-a3: '#{$silver}a3';
$silver-bb: '#{$silver}bb';

$light-tw-2 : #455560;
$primary-tw: #6b47f5;

$amreican-silver: #cfcfcf;
$philippine-silver: #b4b4b4;
$silver-sand: #b8bcc5;
$princeton-orange: #6B47F5;
$nickel: #676F7C;
$gray-x11: #bfbebe;
$gainsboro: #d9dce1;
$onyx: #34383e;
$bright-gray: #efefee;
$auro-metal-saurus: #6c757d;
$roman-silver: #818B9B;
$gunmetal: #213039;
$chinese-white: #DFE1E2;
$cultured: #F6F7F8;
$dark-sky-blue: #6B47F5;
$honeydew: #E3FFEF;
$phthalo-green: #0E311E;
$cadmium-orange: #6B47F5;
$air-superiority-blue: #6E9FBB;
$air-superiority-blue-a3: #6e9fbba3;
$anti-flash-white: #F0F5F8;
$linen: #6B47F5;
$platinum: #e6e6e6;
$apple: #6ab151;
$chinese-black: #111;
$light-silver: #D8D8D8;
$light-gray: #cfd2d5;
$rich-electric-blue: #009dde;
$sunset-orange: #F5584D;
$lilac-luster: #b1a0a0;
$spanish-crimson: #db1753;
$manatee: #949eac;
$dark-charcoal: #33333391;
$gray-html-css-gray: #80808061;
$isabelline: #F3EFEF;
$pumpkin: #fd7e14;

$ghost-white: #f9fafc;
$cadet-blue: #5f9ea0;
$dark-midnight-blue: #003366;
$chinese-silver: #cdcdcd;
$flesh: #ffead5;

$maastricht-blue-0f: #091E420f;
$maastricht-blue-0d: #091E420d;
$maastricht-blue-26: #091E4226;

$american-bronze: #332500;

$american-silver: #cdced1;

.text-secondary {
	color: $secondary-color !important;
}

$myColors: (
	"secondary-color": $secondary-color,
	"grey-67": $grey-67,
	"black-25": $black-25,
	"primary-color": $primary-color,
	"black": $black,
	"gold-d8": $gold-d8,
	"yellow-C1": $yellow-C1,
);

@each $name,
$colors in $myColors {
	$index: index($myColors, $colors);
}