
.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a:hover {
    text-decoration: none !important;
}

#homeOverview>div>div:nth-child(2)>div>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputOne>div>div>div>div>input {
    padding-inline: 0 !important;
}

#homeOverview>div>div:nth-child(2)>div>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputThree>div>div>div>div>input {
    padding-inline: 0 !important;

}

.landingContainer>div>div>div.row.travelInsurance-layout-container>div:nth-child(1)>div>div:nth-child(2)>div.banner-image>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputOne>div>div>div>div>input {
    padding-inline: 0 !important;
}

.landingContainer>div>div>div.row.travelInsurance-layout-container>div:nth-child(1)>div>div:nth-child(2)>div.banner-image>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputThree>div>div>div>div>input {
    padding-inline: 0 !important;

}

#medicalMarPracticeID>div>div:nth-child(2)>div>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputOne>div>div>div>div>input {
    padding-inline: 0 !important;
}


#medicalMarPracticeID>div>div:nth-child(2)>div>div>div.banner-top>div.inputContainer-product-banner.mt-2>div>div.inputThree>div>div>div>div>input {
    padding-inline: 0 !important;
}

.MuiOutlinedInput-input {
    padding: 18.5px 0 !important;
}

#root>div.vh-100.\31>div:nth-child(1)>div.col-12.paddingContainer.landingContainer>div>div>div.page-wrapper>div.enquery-form-page>div>div.forms-list-root>div>div:nth-child(1)>div>div>div>img,
#root>div.vh-100.\31>div:nth-child(1)>div.col-12.paddingContainer.landingContainer>div>div>div.page-wrapper>div.enquery-form-page>div>div.forms-list-root>div>div:nth-child(2)>div>div>div>img,
#root>div.vh-100.\31>div:nth-child(1)>div.col-12.paddingContainer.landingContainer>div>div>div.page-wrapper>div.enquery-form-page>div>div.forms-list-root>div>div:nth-child(4)>div>div>div>img {
    transform: translateX(20px);
}

#root>div.vh-100.\31>div:nth-child(2)>div.col-12.paddingContainer.landingContainer>div>div>div.page-wrapper>div.enquery-form-page>div>div.forms-list-root>div>div:nth-child(1)>div>div>div#root>div.vh-100.\31>div:nth-child(2)>div.col-12.paddingContainer.landingContainer>div>div>div.page-wrapper>div.enquery-form-page>div>div.forms-list-root>div>div:nth-child(1)>div>div>div img {
    transform: translateX(15px);
}

#yearOfBirth>input {
    border: none;
}

a:hover {
    color: unset !important;
}


.eid-banner-title {
    font-size: 42px;
    font-weight: 300;
    color: #FFF;
    text-align: center;
}

.eid-banner-sub-title {
    font-size: 46px;
    font-weight: 500;
    color: #FFF;
    text-align: center;
}

.eid-banner-offer-card-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #FFF;
    text-align: center;
}

.eid-banner-offer-card-content-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 4px;
    color: #FFF;
    text-align: center;
}

.eid-banner-offer-card-content {
    font-size: 16px;
    font-weight: 400;
    color: #FFF;
    text-align: center;
    font-style: normal;
}

.eid-banner-btn {
    width: 224px;
    height: 58px;
    padding: 12px;
    background-color: white;
    border-radius: 12px;
    color:#5A3360;
    font-size: 16;
    font-weight: 700;
}

@media (max-width: 600px) {
    .eid-banner-title {
        font-size: 24px;
        font-weight: 300;
    }
    
    .eid-banner-sub-title {
        font-size: 28px;
        font-weight: 500;
        max-width: 220px;
    }
    
    .eid-banner-offer-card-title {
        font-size: 14px;
        font-weight: 700;
    }
    

    .eid-banner-offer-card-content-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 6px;
    }
    
    .eid-banner-offer-card-content {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 24px;
    }

    .eid-banner-btn {
        color:#9E5A4B;
    }
    
}

.collapse{
    visibility: visible !important;
}
