@import "assets/scss/abstracts/variables.scss";
@import "assets/scss/abstracts/colors.scss";

.generic-btn {
    width: 100%;
    background-color: transparent;
    color: $eerie-black;
    border: 1px solid $gainsboro !important;
    border-radius: 8px;
    font-size: 0.875rem !important;
    font-weight: 800;
    padding: 30px !important;
    text-align: start;

    &.active {
        background-color: $bright-purple;
        color: $white;
        border: none !important;
    }

    &.center {
        justify-content: center !important;
    }

    .icon {
        padding-inline-end: 1.25rem;
    }

    .margin-start {
        margin-inline-start: 1rem;
    }

    &.custom-width {
        padding: 14px !important;
        width: fit-content;
        white-space: nowrap;
    }

    &.mobile-btn {
        background-color: $primary-tw;
        text-align: center;
    }

    &.mobile-btn-default {
        background-color: $lightgrey-tw;
        text-align: center;
        border: none !important;
    }

    &.padding-custom {
        padding: 0 !important;
    }

    &.product-catalog {
        border: 2px solid $bright-purple !important;
        border-radius: 8px;
        padding: 23px 0.75rem !important;
    }

    .margin-end {
        margin-inline-end: 1rem;
    }

    &.sticky-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        // height: 100px;
        display: flex;
        z-index: 1040;
        padding: 16px 16px 32px !important;
    }
}

.language-alignment-right {}