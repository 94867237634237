@import "../../../../assets/scss/abstracts/font";
@import "assets/scss/abstracts/variables.scss";
@import "assets/scss/abstracts/colors.scss";

.products-container {
  width: 100%;
  position: relative;
  display: flex;
  margin-bottom: 9rem;

  // .MuiButton-root{
  //   text-transform: none;
  // }
  @media (max-width: 1300px) {
    margin-bottom: 27rem;
  }

  @media (max-width: 1100) {
    margin-bottom: 26rem;
  }

  .grid-percentage {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .content-container {
      width: auto;
      display: flex;
      flex: 1;

      .heading-container {
        // background: rgb(103, 154, 180);
        padding-top: 80px;
        padding-left: 80px;
        position: relative;
        border-radius: 80px 0px 0px 20px;
        // height: 560px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;

        .artwork {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;

          background-image: url("../../../../assets/svg/ProductsArtwork.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .rtl-artwork-scale {
          transform: scaleX(-1);
        }

        .content {
          padding-top: 2rem;
          z-index: 2;

          h2 {
            font-size: 40px;
            letter-spacing: -0.02em;
            color: $white;
            font-weight: 700;
          }

          .subheading {
            font-weight: 400;
            font-size: 1.1rem;
            color: $white;
          }

          .categoriesLinks {
            display: flex;
            flex-direction: row;

            ul {
              margin: 0;
              padding: 0;
            }

            .category {
              list-style-type: none;
              display: inline-flex;
              // margin-right: 1rem;


              button {
                padding: 8px 14px;
                color: $white;
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;

                border-radius: 100px;
                transition: 0.5s all ease-in-out;

                img {
                  border-radius: 50%;
                  width: 32px;
                  height: 32px;
                  padding: 7px;
                  margin-right: 7px;
                }
              }

              &:hover {
                button {
                  background-color: $dark-purple-hover;
                  border-radius: 100px;
                }

              }

              &.active {
                button {
                  background: $white;
                  box-shadow: 0px 7px 10px rgba(9, 30, 66, 0.05);
                  color: $newLight-tw;
                  font-weight: 800;

                  img {
                    background: $bright-purple;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .featured-image {
      display: flex;
      transition: 0.5s all ease-in-out;
      position: relative;
      width: 43%;

      img {
        border-radius: 0 20px 80px;
        transition: 0.5s all ease-in-out;
      }

      &.md {
        width: 100%;
      }

      .nav-buttons {
        position: absolute;
        top: 125px;
        right: 79px;

        button {
          width: 32px;
          height: 32px;
          background: $bright-purple;
          box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.6);
          border-radius: 32px;
          padding: 0;
          display: inline-flex;
          justify-content: center;
          align-self: center;
          min-width: auto;
          margin: 0 8px;

          &.next {
            width: 50px;
          }

          svg {
            width: 12px;
            fill: $white;
          }
        }
      }

      .nav-buttons-rtl {
        position: absolute;
        top: 132px;
        right: 130px;

        @media only screen and (min-width: 1000px) and (max-width: 1399px) {
          right: 56px;
        }

        @media only screen and (min-width: 1800px) and (max-width: 2500px) {
          right: 372px;
        }

        button {
          width: 32px;
          height: 32px;
          background: $bright-purple;
          box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.6);
          border-radius: 32px;
          padding: 0;
          display: inline-flex;
          justify-content: center;
          align-self: center;
          min-width: auto;
          margin: 0 8px;

          &.next {
            width: 50px;
          }

          svg {
            width: 12px;
            fill: $white;
          }
        }
      }
    }

    .cards-container {
      z-index: 2;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 80px;
      grid-gap: 24px;
      gap: 24px;
      top: 18.063rem;
      position: absolute;

      @media only screen and (max-width: 1100px) {
        top: 20rem
      }

      @media only screen and (max-width: 1199px) {
        // margin-top: -300px;
      }

      &.md {
        margin-top: -180px;
      }

      &.mdlg {
        // margin-top: -400px;
        // @media only screen and (max-width: 1199px) {
        //   margin-top: -300px;
        // }
        // @media only screen and (max-width: 1599px) {
        //   margin-top: -280px;
        // }
      }
    }

    .cards-footer-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 30px 80px;
      margin-bottom: 80px;

      Button {
        &.viewAll {
          font-style: normal;
          font-weight: 800;
          font-size: 0.9rem;

          color: $newLight-tw;

          svg {
            fill: $bright-purple;
            margin: 0 10px;
            width: 15px;
          }
        }

        &.MuiButton-textPrimary:hover {
          background: unset;
        }
      }
    }
  }
}

.language-alignment-right {
  .products-container {
    .grid-percentage {
      .content-container {
        .heading-container {
          padding-left: 10px;
          padding-right: 80px;
          border-radius: 0px 80px 20px 0px;

          .content {

            h2 {}

            .subheading {}

            .categoriesLinks {
              ul {}

              .category {
                margin-left: 1rem;

                button {
                  img {
                    margin-left: 7px;
                    margin-right: 0px;
                  }
                }

                &.active {
                  button {
                    img {}
                  }
                }
              }
            }
          }
        }
      }

      .featured-image {
        img {
          border-radius: 20px 0 80px;
        }

        &.md {}

        .nav-buttons {
          left: none !important;

          svg {
            transform: rotate(180deg);
          }
        }
      }

      .cards-container {
        padding-left: 0;
        padding-right: 80px;
      }

      .cards-footer-container {
        Button {
          &.viewAll {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.productsAnimation-enter {
  opacity: 0.01;
  transform: scale(0.8);
}

.productsAnimation-enter.productsAnimation-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms ease-in;
}

.productsAnimation-leave {
  opacity: 1;
  transform: scale(1);
}

.productsAnimation-leave.productsAnimation-leave-active {
  opacity: 0.01;
  transform: scale(0.8);
  transition: all 200ms ease-in;
}