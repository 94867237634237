@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";
@import "assets/scss/abstracts/variables.scss";
@import "assets/scss/abstracts/colors.scss";

.menuclaim {
	// box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
	border: 1px solid #d9dce1;
	min-width: 300px;
	width: 300px;
	border-radius: 8px;

}

.page-navbar-menu-desktop {
	margin: 0;
	z-index: 2;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	min-height: 100vh;
	padding: 30px 30px;
	box-shadow: 0px 50px 60px rgba(9, 30, 66, 0.15);
	background: $white;

	.deactive {
		font-weight: 800;
		font-size: 1.75rem;
		color: #000000;
	}

	.active {
		font-weight: 800;
		font-size: 1.75rem;
		color: $bright-purple !important;
	}

	.page-navbar-title-section {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $neutral1;
		padding-bottom: 30px;
		margin-bottom: 30px;

		.right-section {
			margin-left: auto;
			padding-left: 15px;
			display: flex;
			align-items: center;

			.close-icon {
				margin-left: 30px;
				cursor: pointer;
			}

			button {
				display: flex;
				align-items: center;
				margin-left: 10px;
				outline: 0;
				border: 0;
				background: $bright-purple;
				border-radius: 8px;
				height: 46px;
				padding: 10px 20px;
				font-weight: 400;
				font-size: fontSize(16);
				color: $white;

				.global_link_flex {
					align-items: center;
				}

				@include media-breakpoint-tab-port {
					font-size: fontSize(13);
				}

				img {
					display: block;
					margin-left: 10px;
				}

				&.greyBtn {
					background: $primary-tw;
				}
			}
		}

		.left-section {

			span {
				color: $silver;
				font-size: fontSize(20);
			}

			.menu-tabs {
				display: flex;
				align-items: center;

				>div {
					padding-right: 15px;
					margin-right: 15px;
					border-right: 1px solid $eerie-black-1a;
					font-weight: 800;
					font-size: fontSize(28);
					color: $black;
					cursor: pointer;

					&.active {
						color: $bright-purple !important;
					}

					&:last-child {
						border-right: 0;
					}
				}
			}
		}
	}

	.menu-section-form {
		background: $lightgrey-tw;
		border-radius: 12px;
		padding: 30px;

		.input-div {
			margin-bottom: 10px;
			position: relative;
			z-index: 1;

			img {
				position: absolute;
				z-index: 1;
				left: 10px;
				top: 10px;
			}

			input {
				background: $white;
				box-shadow: 0px 4px 10px rgba(9, 30, 66, 0.06);
				border-radius: 4px;
				width: 100%;
				border: 0;
				outline: 0;
				padding: 10px 20px;
				padding-left: 50px;
				font-weight: 400;
				font-size: fontSize(16);
			}
		}

		.form-action {
			text-align: left;
			border-top: 1px solid $eerie-black-1a;
			padding-top: 15px;
			margin-top: 15px;

			button {
				font-weight: 400;
				font-size: fontSize(16);
				color: $white;
				background: $princeton-orange;
				border-radius: 8px;
				outline: 0;
				border: 0;
				padding: 8px 25px;
			}
		}
	}

	.top-menu-section {
		display: flex;
		align-items: flex-start;

		.left-section {
			flex: 1 0 0;

			&.investor-columns {
				column-count: 2;
				column-gap: 20px;
			}
		}

		.right-section {
			margin-left: 30px;
			flex: 0 0 350px;
			padding: 20px;
			border-radius: 8px;
		}

		&.left-section-small {
			.left-section {
				flex: 0 0 350px;

				@include media-breakpoint-tab-port {
					flex: 0 0 250px;
				}
			}

			.right-section {
				flex: 0 0 calc(100% - 380px);
				max-width: calc(100% - 380px);

				@include media-breakpoint-tab-port {
					flex: 0 0 calc(100% - 280px);
					max-width: calc(100% - 280px);
				}
			}
		}

		&.half-column-section {
			.left-section {
				flex: 0 0 calc(50% - 30px);
				margin-right: 30px;
			}

			.right-section {
				flex: 0 0 50%;
				margin-left: 0;
			}
		}

		.small-title {
			font-size: fontSize(28);
			color: $black;
			margin-bottom: 20px;
		}

		.bold-menu,
		.bold-menu-link {
			display: flex;
			align-items: center;
			text-decoration: none;
			font-weight: 800;
			font-size: fontSize(36);
			margin-bottom: 8px;
			color: $silver-dark;
			cursor: pointer;

			.global_link_flex {
				align-items: center;
			}

			@include media-breakpoint-tab-port {
				font-size: fontSize(24);
			}

			@include media-breakpoint-med-desktop {
				font-size: fontSize(32);
			}

			.black-color {
				display: none;
			}

			&:hover {
				color: $bright-purple !important;

				// .orange-color {
				// 	display: none;
				// }
			}

			img {
				// margin-left: 15px;
				display: block;
			}
		}
	}

	.menu-title-section {
		display: flex;
		align-items: center;
		border-bottom: 1px solid $neutral1;
		padding-bottom: 10px;
		margin-bottom: 30px;

		.image {
			margin-right: 15px;

			img {
				width: 60px;
			}
		}

		.title-content {
			strong {
				color: $black;
				font-size: fontSize(40);
				font-weight: 800;
				line-height: 1.2;
				margin-bottom: 8px;
				display: block;
			}

			p {
				margin: 0;
				color: $newLight-tw;
				font-size: fontSize(18);
				font-weight: 400;
			}
		}
	}

	.enterprise-menu {
		.menu-list-section-root {
			flex-wrap: wrap;

			.menu-list-section {
				flex: 0 0 50%;

				&.property-insurance {
					flex: 1 0 0;
					display: flex;
					flex-wrap: wrap;

					.menu-list-title {
						flex: 0 0 100%;
					}

					.menu-details {
						flex: 0 0 25%;

						&:nth-last-child(2) {
							border-bottom: 0;
						}

						&:nth-last-child(3) {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}

	.investor-menu-list {
		// display: flex;
		flex-wrap: wrap;
		max-width: 870px;

		.bold-menu {
			flex: 0 0 50%;
			padding-right: 20px;
		}

		.bold-menu-link {
			flex: 0 0 50%;
			padding-right: 20px;
		}
	}

	.stock-price-box {
		background: $black;
		padding: 30px;
		box-shadow: 0px 10px 20px rgba(238, 117, 0, 0.2);
		border-radius: 12px;
		color: $white;

		.title {
			font-weight: 700;
			font-size: fontSize(16);
			margin-bottom: 20px;
		}

		.time {
			font-weight: 400;
			font-size: fontSize(16);
			margin-bottom: 0;
		}

		.price {
			font-weight: 700;
			font-size: fontSize(40);
			margin-bottom: 0;
			color: $bright-purple;
		}

		.lastprice {
			font-weight: 400;
			font-size: fontSize(14);
			margin-bottom: 20px;
		}

		.price-list {
			>div {
				display: flex;
				align-items: center;
				border-bottom: 1px solid $white-fff1a;
				;
				justify-content: space-between;
				font-weight: 400;
				font-size: fontSize(16);
				margin-bottom: 0;

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}

	@include media-breakpoint-med-desktop {
		.menu-list-section-root {
			.menu-list-section {
				.menu-list-title {
					min-height: 48px;
				}

				.menu-details {
					min-height: 95px;
				}
			}
		}
	}

	@media screen and (max-width: 1399px) and (min-width: 1360px) {
		.menu-list-section-root {
			.menu-list-section {
				.menu-list-title {
					min-height: initial;
				}

				.menu-details {
					min-height: 71px;
				}
			}
		}
	}
}

.language-alignment-right {
	.menuclaim {
		img {
			transform: rotate(180deg);
		}
	}

	.page-navbar-menu-desktop {
		.page-navbar-title-section {
			.left-section {
				.menu-tabs {
					>div {
						padding-right: 0;
						margin-right: 0;
						border-right: 0;
						padding-left: 15px;
						margin-left: 15px;
						border-left: 1px solid $neutral1;

						&:last-child {
							border-left: 0;
						}
					}
				}
			}

			.right-section {
				margin-left: 0;
				margin-right: auto;

				.close-icon {
					margin-left: 0;
					margin-right: 30px;
				}

				button {
					img {
						transform: scaleX(-1);
						margin-left: 0;
						margin-right: 10px;
					}
				}
			}
		}

		.menu-section-form {
			.input-div {
				img {
					right: 10px;
					left: auto;
				}

				input {
					padding-left: 20px;
					padding-right: 50px;
				}
			}

			.form-action {
				text-align: right;
			}
		}

		.top-menu-section {
			.bold-menu {
				img {
					transform: scaleX(-1);
					margin-left: 0;
					margin-right: 15px;
				}
			}

			.bold-menu-link {
				img {
					transform: scaleX(-1);
					margin-left: 0;
					margin-right: 15px;
				}
			}
		}
	}
}