@import "src/assets/scss/abstracts/font";
@import "assets/scss/abstracts/colors.scss";

.product-container {
  &.alternate {
    .product-card {
      position: relative;
      justify-content: start;
      background: $dark;
      height: 100%;

      .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0.51) 100%);
        }

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .MuiCardContent-root {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 24px 24px 0;
      }

      .MuiCardActions-root {
        z-index: 2;
      }

      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        letter-spacing: -0.01em;
        color: $white;
      }

      h5 {
        font-style: normal;
        font-size: 0.9rem;
        color: $white;
      }

      h6 {}

      .cardActions {
        Button {
          &.explore {
            background: $bright-purple;
            border-radius: 6px;
            text-transform: none;
            padding: 14px 16px;

            font-style: normal;
            font-weight: 300;
            font-size: 0.9rem;
            color: $white;

            svg {
              margin-left: 5px;
              width: 12px;
              fill: $white;
            }
          }
        }
      }
    }
  }
}