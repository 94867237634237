@import "../../../assets/scss/abstracts/abstracts";
@import "assets/scss/abstracts/colors.scss";

.select-section {
  .selectArrowIcon {
    position: relative;
  }

  label {
    font-size: fontSize(14px);
    color: $black-25;
    line-height: 19px;
  }

  .Select--is-disabled {
    border-radius: 4px;
    font-size: fontSize(14px);
    line-height: 18px;
    color: $text-black-1a;
    opacity: 0.9;
    background-color: $white;
    cursor: not-allowed;
  }

  option:first-of-type {
    display: none;
  }

  i {
    position: absolute;
    right: 10px;
    top: 15px;
    pointer-events: none;
  }
}

.table-select_dropdown {
  .select-section {
    margin-top: 0;
    width: 100%;

    .Select__control {
      height: 34px;
      margin-inline: 1rem;
    }
  }
}

.bannerSelectInput {
  &.is-padding {
    .Select__control {
      min-height: 56px;
      padding: 16px;
      margin-inline: 1rem;
    }
  }

  &.no-outer-padding{
    .Select__control {
      min-height: 56px;
      padding: 16px;
    }
  }
}

.searchBox-rtl {
  &::placeholder {
    text-align: right !important;
  }
}

.padLeftIcon {
  padding-left: 60px !important;
}

.leftIconBox {
  .leftIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-57%);

    .Inputicon {
      transform: translateX(26px);
    }
  }
}

.rightIconBox {
  .rightIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-57%);
    width: fit-content;

    &.adjust-icon {
      top: unset;
      transform: translateY(50%);
      left: 37px;
    }

    .Inputicon {
      transform: translateX(22px);
    }
  }
}

.search-icon-rtl {
  cursor: pointer;

  .icon-search:before {
    color: $text;
  }

  i {
    position: absolute;
    left: 10px;
    top: 13px;
  }
}

.search-icon {
  cursor: pointer;

  .icon-search:before {
    color: $bright-purple;
  }

  i {
    position: absolute;
    right: 10px;
    top: 13px;
  }
}

.language-alignment-right {
  .rightIconBox {
    .rightIcon {
      &.adjust-icon {
        left: unset !important;
        right: 55px !important;
      }
    }
  }

  .normal-search {
    .leftIconBox {
      .leftIcon {
        .Inputicon {
          transform: translateX(-22px);
        }
      }
    }
  }

  // .Select__control {
  //   margin-right: 2rem;
  // }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .normal-search {
    .searchBox-rtl {
      padding: 10px 53px 10px 51px !important;
      border: none;
      z-index: 2;
      position: relative;

      &:focus {
        background-color: $white;
      }

      &::placeholder {
        color: $silver;
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .searchBox {
      padding: 10px 25px 10px 51px;
      border: none;

      &:focus {
        background-color: $white;
      }

      &::placeholder {
        color: $silver;
        font-weight: 400;
        font-size: 1rem;
      }
    }

    .search-icon {
      cursor: pointer;

      .icon-search:before {
        color: $bright-purple;
      }

      i {
        position: absolute;
        right: 10px;
        top: 15px;
      }
    }
  }
}

.rightIconBox .rightIcon {
  position: absolute;
  transform: translateY(-5%);
  width: fit-content;
  z-index: 2;
}
